import { NextApiRequest, NextApiResponse } from 'next';
import getConfig from 'next/config';
import fetch from 'isomorphic-unfetch';
import { v4 as uuidv4 } from 'uuid';
import {
  X_RID,
  LOCALE,
  ACCOUNT_ID,
  DC3_LOCALE,
  X_CLOUD_DEBUG,
  AUTH_TOKEN_KEY,
  ORGANIZATION_ID,
  GRAPHQL_TOKEN_KEY,
  CORPORATE_IDENTITY,
  CHANNEL_TYPE,
  CHANNEL,
  X_ENABLE_MOCK_MODE,
  X_DC3_APP_NAME,
  X_API_KEY,
  SENTRY_TRACE,
  SENTRY_BAGGAGE,
  X_FEATURES,
} from '@dc3/utils/constants';
import * as Sentry from '@sentry/nextjs';

export const graphqlProxyHandler = async (
  req: NextApiRequest,
  res: NextApiResponse,
) => {
  const { serverRuntimeConfig } = getConfig();
  const { GRAPHQL_URL, APP_NAME } = serverRuntimeConfig;
  const xRidValue = req.headers[X_RID];
  const xCloudDebugValue = req.headers[X_CLOUD_DEBUG];
  const orgIdHeaderValue = req.headers[ORGANIZATION_ID];
  const accountIdHeaderValue = req.headers[ACCOUNT_ID];
  const localeValue = req.headers[LOCALE] || req.cookies[DC3_LOCALE];
  const enableMockMode = req.headers[X_ENABLE_MOCK_MODE];
  const xApiKeyValue = req.headers[X_API_KEY];
  const xGraphqlUrlOverride = req.headers['x-graphql-url-override'];
  const sentryTrace = req.headers[SENTRY_TRACE];
  const sentryBaggage = req.headers[SENTRY_BAGGAGE];
  const xFeatures = req.headers[X_FEATURES];

  if (xRidValue) {
    Sentry.getCurrentScope().setTag('request_id', xRidValue as string);
  }

  const headers = {
    'Content-Type': 'application/json',
    [X_RID]: uuidv4(),
    [CORPORATE_IDENTITY]: req.headers[CORPORATE_IDENTITY],
    [GRAPHQL_TOKEN_KEY]: req.cookies[AUTH_TOKEN_KEY],
    [CHANNEL_TYPE]: req.headers[CHANNEL_TYPE],
    [CHANNEL]: req.headers[CHANNEL],
    [X_DC3_APP_NAME]: APP_NAME,
    ...(xFeatures && { [X_FEATURES]: xFeatures }),
    ...(localeValue && { [LOCALE]: localeValue }),
    ...(xRidValue && { [X_RID]: xRidValue }),
    ...(xCloudDebugValue && { [X_CLOUD_DEBUG]: xCloudDebugValue }),
    ...(orgIdHeaderValue && { [ORGANIZATION_ID]: orgIdHeaderValue }),
    ...(accountIdHeaderValue && { [ACCOUNT_ID]: accountIdHeaderValue }),
    ...(enableMockMode && { [X_ENABLE_MOCK_MODE]: enableMockMode }),
    ...(xApiKeyValue && { [X_API_KEY]: xApiKeyValue }),
    ...(sentryTrace && { [SENTRY_TRACE]: sentryTrace }),
    ...(sentryBaggage && { [SENTRY_BAGGAGE]: sentryBaggage }),
  } as Record<string, string>;

  return fetch(xGraphqlUrlOverride ? xGraphqlUrlOverride : GRAPHQL_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(req.body),
  })
    .then((response: Response) => response.text())
    .then((body) => res.end(body))
    .catch((reason: Error) => {
      console.error('Error in graphql call', reason);
      res.end(JSON.stringify({ errors: [reason], data: null }));
    });
};
