export const flatten = <T extends Record<string, {}>>(
  object: T,
  path: string | null = null,
  separator = '.',
): T =>
  Object.keys(object).reduce((acc: T, key: string): T => {
    const newPath = [path, key].filter(Boolean).join(separator);

    return typeof object?.[key] === 'object'
      ? {
          ...acc,
          ...flatten(object[key] as Record<string, {}>, newPath, separator),
        }
      : { ...acc, [newPath]: object[key] };
  }, {} as T);
