/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import { useFormContext, type UseFormReturn } from 'react-hook-form';
import api from '../../api';

import { getLoginHeaders, mapErrorMessage } from './CoreLogin.utils';
import {
  AxiosLoginResponse,
  LoginRequest,
  LoginResponse,
} from '../../api/types';
import getConfig from 'next/config';
import { useI18n } from '@dc3/utils/hooks';
import { getEnvironmentVariable } from '@dc3/utils/constants';
import { Box } from '@dc3/ui/box';
import { LoadingOverlay } from '@dc3/ui/loading';
import { Message } from '@dc3/ui/message';
import { NextRouter } from 'next/router';

const basePath = getEnvironmentVariable('BASE_PATH');

interface CoreLoginProps {
  children: (methods: UseFormReturn<Record<string, any>>) => ReactNode;
  /** Optional hook for the tenant to act on the response of the login request */
  onLoginResponse?: (response: AxiosLoginResponse) => boolean;
  /** Optional hook for the tenant to validate and tweak the redirectUrl */
  beforeRedirect?: (redirectUrl: string, jwt: string) => string;
  router: NextRouter | null;
}

export const CoreLogin = ({
  children,
  onLoginResponse,
  beforeRedirect,
  router,
}: CoreLoginProps) => {
  const translate = useI18n();
  const methods = useFormContext();
  const [loginResponse, setLoginResponse] = useState<LoginResponse>();
  const [continueLogin, setContinueLogin] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string | null>();
  const {
    publicRuntimeConfig: { SWORDFISH_ENDPOINT },
  } = getConfig();
  // STEP 1: The callback when the form is submitted.
  const onSubmit = async (data: Record<string, string>) => {
    setLoginError(null);
    setLoading(true);

    const requestData: LoginRequest = {
      username: data.username,
      password: data.password,
      redirectchain: '',
    };

    try {
      // Call Swordfish for the actual authentication
      const response = await api.post<LoginResponse>(
        `${SWORDFISH_ENDPOINT}/v2/login`,
        requestData,
        {
          headers: getLoginHeaders(),
        },
      );

      // save the response locally
      setLoginResponse(response.data);

      // Trigger the Tenant wrapper hook if available
      const tenantHookResponse =
        (onLoginResponse && onLoginResponse(response)) || true;

      // Set if we can continue with the login process
      setContinueLogin(tenantHookResponse);
    } catch (err) {
      setLoginError(mapErrorMessage(err, translate));
      setLoading(false);
    }
  };

  // STEP 2: Continue login process after optional tenant logic has been executed.
  useEffect(() => {
    if (continueLogin && loginResponse) {
      const { idToken, refreshToken } = loginResponse;
      if (idToken && refreshToken) {
        onSuccessfulAuthentication(idToken, refreshToken);
      } else {
        setLoginError('Missing required property to create cookie');
      }
    }
  }, [continueLogin]);

  // STEP 3: Handle the successful login response and trigger the redirect
  const onSuccessfulAuthentication = (
    idToken: string,
    refreshToken: string,
  ) => {
    // Set state for success message
    setLoginSuccess(true);

    // Set http-only cookies on current domain by calling Next API endpoint
    // Call axios directly instead of `api` instance, since we don't want to use baseURL for Next api.
    axios
      .post(`${basePath}/api/set-auth-cookies`, { idToken, refreshToken })
      .then(() => {
        redirect(idToken);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  // STEP 4: Handle redirect
  const redirect = (idToken: string) => {
    // Extracting redirectTo, id, and hash from the router and window.location
    const redirectTo = (router?.query.redirectTo as string) || '/';
    const id = router?.query.id;
    const hash = window.location.hash;
    const redirectToQueryParam = `${redirectTo}${id ? `&id=${id}` : ''}${hash}`;
    window.location.href =
      (beforeRedirect && beforeRedirect(redirectToQueryParam, idToken)) ||
      redirectToQueryParam;
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      {loginError && (
        <Box marginBottom={2}>
          <Message type="error">{translate(loginError)}</Message>
        </Box>
      )}
      {loginSuccess && (
        <Box marginBottom={2}>
          <Message type="success">{translate('login.loginSuccessful')}</Message>
        </Box>
      )}
      <LoadingOverlay isLoading={loading}>
        {/**
         * The login UI is tenant specific.
         * We'll use the render props pattern provided by react-hook-form in order
         * to register the login fields in the Tenant UI on the forms instance in this core component.
         **/}
        {children({ ...methods })}
      </LoadingOverlay>
    </form>
  );
};
