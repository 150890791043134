import {
  GQLEntityContext,
  GQLIntent,
  GQLIntentAttribute,
} from '@dc3/data-access/graphql';
import { PageTypes } from '../page-types';
import { Intent } from './intent-names';

export const createEvent = (
  event_name: Intent,
  entity_contexts: GQLEntityContext[],
  attributes: Record<string, PageTypes | string>,
): GQLIntent => ({
  event_name,
  client_event_timestamp: new Date().toISOString(),
  ui_components: [],
  entity_contexts,
  attributes: Object.entries(attributes).map(
    ([key, value]): GQLIntentAttribute => ({ key, value }),
  ),
});
