export enum Pages {
  Dashboard = 'dashboard',
  Campaign = 'campaign',
  Quotation = 'quotation',
  Organization = 'organization',
  Catalog = 'catalog',
  Edith = 'edith',
  Delivery = 'delivery',
  Search = 'search',
  DataInspection = 'data-inspection',
  Alpha = 'alpha',
  Settings = 'settings',
  FeatureToggles = 'feature-toggles',
  Hyperloop = 'hyperloop',
  Role = 'role',
  Experimentation = 'experimentation',
}

export enum SubPages {
  Quotations = 'quotation',
  Holds = 'holds',
  Proposals = 'proposals',
  Pending = 'pending',
  Organizations = 'overview',
  Users = 'users',
  Matrix = 'matrix',
  Management = 'management',
  Synonyms = 'synonyms',
  SuppressResults = 'suppress-results',
  Questions = 'questions',
  Export = 'export',
  MasterCatalog = 'master-catalog-mapping',
  SalesCatalog = 'sales-catalog',
  Duplicates = 'duplicates',
  Spelling = 'spelling',
  SuppressedDeliveryOptions = 'delivery-options',
  FreightCharges = 'freight-charges',
  DeliveryPriority = 'delivery-priority',
  DeliveryTranslations = 'delivery-translations',
}
