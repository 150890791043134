import {
  StyledDateTimeRangePicker,
  DateTimePicker,
  WeekNumber,
  WeekNumberWrapper,
} from './DateTimeRangePicker.styles';
import { Box } from '@dc3/ui/box';
import { useUserData } from '@dc3/utils/auth';
import { startOfWeek, getISOWeek } from 'date-fns';
import { getDateLocale } from '@dc3/utils/translations';
import { Input } from '../input/Input';
import { PickersDay } from '@mui/x-date-pickers-pro';
import noop from 'lodash/noop';

interface DateTimeRangePickerProps {
  startDateTime: Date;
  endDateTime: Date;
  startMinDateTime?: Date | undefined;
  endMinDateTime?: Date | undefined;
  startDateFieldLabel?: string;
  endDateFieldLabel?: string;
  disableErrorState?: boolean;
  dateTimeFormat?: 'dd-MM-yy HH:mm' | 'MM-dd-yy HH:mm';
  onStartDateChange: (value: Date) => void;
  onEndDateChange: (value: Date) => void;
  disabled?: boolean;
}

export const DateTimeRangePicker = ({
  startDateTime,
  endDateTime,
  startMinDateTime = undefined,
  endMinDateTime = undefined,
  disableErrorState,
  startDateFieldLabel = 'Start Date',
  endDateFieldLabel = 'End Date',
  dateTimeFormat = 'dd-MM-yy HH:mm',
  onStartDateChange,
  onEndDateChange,
  disabled,
}: DateTimeRangePickerProps) => {
  const { country } = useUserData();
  const renderDay = (day, selectedDate, dateRangePickerDayProps) => {
    const dateLocale = getDateLocale(country?.locale);
    const dateObject = new Date(day as unknown as Date);
    const isStartOfWeek =
      startOfWeek(dateObject, { locale: dateLocale }).getTime() ===
      dateObject.getTime();
    if (isStartOfWeek) {
      const weekNumber = getISOWeek(dateObject);
      return (
        <WeekNumberWrapper {...dateRangePickerDayProps}>
          <WeekNumber>{weekNumber}</WeekNumber>
          <PickersDay {...dateRangePickerDayProps} />
        </WeekNumberWrapper>
      );
    } else {
      return <PickersDay {...dateRangePickerDayProps} />;
    }
  };

  const errorProp = disableErrorState ? { error: false } : {};

  return (
    <StyledDateTimeRangePicker>
      <Box width="192px">
        <DateTimePicker
          minDateTime={disabled ? undefined : startMinDateTime}
          label={startDateFieldLabel}
          mask="__-__-__ __:__"
          ampm={false}
          value={startDateTime}
          disabled={disabled}
          onChange={noop}
          onAccept={onStartDateChange}
          inputFormat={dateTimeFormat}
          renderDay={renderDay}
          renderInput={({ ref, ...params }) => (
            <Input {...params} {...errorProp} />
          )}
          hideTabs={false}
        />
      </Box>
      <Box width="192px">
        <DateTimePicker
          minDateTime={disabled ? undefined : endMinDateTime}
          mask="__-__-__ __:__"
          label={endDateFieldLabel}
          ampm={false}
          value={endDateTime}
          disabled={disabled}
          onChange={noop}
          onAccept={onEndDateChange}
          renderDay={renderDay}
          inputFormat={dateTimeFormat}
          renderInput={({ ref, ...params }) => (
            <Input {...params} {...errorProp} />
          )}
          hideTabs={false}
        />
      </Box>
    </StyledDateTimeRangePicker>
  );
};
