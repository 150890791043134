export interface Country {
  code: string;
  name: string;
  url: string;
  locale: string;
  organizationId: string;
  groupLegalEntities?: string[];
  buyerCountries?: string[];
}

export const countries: Country[] = [
  {
    code: 'AT',
    name: 'Austria',
    url: '/shop-at/de',
    locale: 'de_AT',
    organizationId: 'LE_50',
  },
  {
    code: 'FR',
    name: 'France',
    url: '/shop-fr/fr',
    locale: 'fr_FR',
    organizationId: 'LE_70',
    groupLegalEntities: ['LE_07'],
  },
  {
    code: 'CH',
    name: 'Switzerland',
    url: '/shop-ch/de',
    locale: 'de_CH',
    organizationId: 'LE_55',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    url: '/shop-cz/cs',
    locale: 'cs_CZ',
    organizationId: 'LE_58',
  },
  {
    code: 'DE',
    name: 'Germany',
    url: '/shop-de/de',
    locale: 'de_DE',
    organizationId: 'LE_10',
    groupLegalEntities: ['LE_01'],
    buyerCountries: ['DE', 'BA', 'BG', 'MK', 'RS', 'US', 'HR', 'SI'],
  },
  {
    code: 'DK',
    name: 'Denmark',
    url: '/shop-dk/da',
    locale: 'da_DK',
    organizationId: 'LE_45',
    groupLegalEntities: ['LE_11'],
  },
  {
    code: 'ES',
    name: 'Spain',
    url: '/shop-es/es',
    locale: 'es_ES',
    organizationId: 'LE_92',
    groupLegalEntities: ['LE_09'],
    buyerCountries: ['ES', 'PT'],
  },
  {
    code: 'FI',
    name: 'Finland',
    url: '/shop-fi/fi',
    locale: 'fi_FI',
    organizationId: 'LE_47', // TODO: replace LE_47 with LE_62 and remove LE_62 from groupLegalEntities after harvest go live. Placing LE_62 in groupLegalEntities as a temporary solution to enable the query on the proposals screen.
    groupLegalEntities: ['LE_13', 'LE_62'],
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    url: '/shop-gb/en',
    locale: 'en_GB',
    organizationId: 'LE_60',
    groupLegalEntities: ['LE_06'],
  },
  {
    code: 'HU',
    name: 'Hungary',
    url: '/shop-hu/hu',
    locale: 'hu_HU',
    organizationId: 'LE_90',
  },
  {
    code: 'IT',
    name: 'Italy',
    url: '/shop-it/it',
    locale: 'it_IT',
    organizationId: 'LE_96',
    groupLegalEntities: ['LE_05'],
  },
  {
    code: 'NL',
    name: 'Netherlands',
    url: '/shop-nl/nl',
    locale: 'nl_NL',
    organizationId: 'LE_30',
    groupLegalEntities: ['LE_04'],
    buyerCountries: ['NL', 'IE'],
  },
  {
    code: 'NO',
    name: 'Norway',
    url: '/shop-no/no',
    locale: 'no_NO',
    organizationId: 'LE_48',
    groupLegalEntities: ['LE_14'],
  },
  {
    code: 'PL',
    name: 'Poland',
    url: '/shop-pl/pl',
    locale: 'pl_PL',
    organizationId: 'LE_20',
    groupLegalEntities: ['LE_02'],
    buyerCountries: ['PL', 'EE', 'LV', 'LT'],
  },
  {
    code: 'RO',
    name: 'Romania',
    url: '/shop-ro/ro',
    locale: 'ro_RO',
    organizationId: 'LE_25',
  },
  {
    code: 'SE',
    name: 'Sweden',
    url: '/shop-se/sv',
    locale: 'sv_SE',
    organizationId: 'LE_46',
    groupLegalEntities: ['LE_12'],
  },
  {
    code: 'SK',
    name: 'Slovakia',
    url: '/shop-sk/sk',
    locale: 'sk_SK',
    organizationId: 'LE_22',
  },
  {
    code: 'BE',
    name: 'Belgium',
    url: '/shop-be/nl',
    locale: 'nl_BE',
    organizationId: 'LE_40',
  },
  {
    code: 'UA',
    name: 'Ukraine',
    url: '/shop-ua/uk',
    locale: 'en_PL',
    organizationId: 'LE_94',
  },
  {
    code: 'SI',
    name: 'Slovenia',
    url: '/shop-si/sl',
    locale: 'sl_SI',
    organizationId: 'LE_10',
  },
  {
    code: 'HR',
    name: 'Croatia',
    url: '/shop-hr/hr',
    locale: 'hr_HR',
    organizationId: 'LE_10',
  },
  {
    code: 'IE',
    name: 'Ireland',
    url: '/shop-ie/en',
    locale: 'en_IE',
    organizationId: 'LE_30',
  },
];
