import React from 'react';
import { AppProps } from 'next/app';
import { getInitialAppProps } from '@dc3/utils/auth';
import { AppProvider } from '@dc3/utils/providers';
import { createEmotionCache, licenseKey } from '@dc3/utils/theme';
import { CacheProvider, EmotionCache } from '@emotion/react';
import dynamic from 'next/dynamic';

import {
  useInitFunctionalLogging,
  usePageViewTracking,
} from '@dc3/utils/functional-logging';

const LinearLoading = dynamic(() => import('./Loading'), {
  ssr: false,
});

const FeedbackLauncher = dynamic(
  () => import('@dc3/feature/feedback').then((mod) => mod.FeedbackLauncher),
  { ssr: false },
);
const QuickImpersonateLauncher = dynamic(
  () =>
    import('@dc3/feature/quick-impersonate').then(
      (mod) => mod.QuickImpersonateLauncher,
    ),
  { ssr: false },
);
const ReleaseNotesLauncher = dynamic(
  () =>
    import('@dc3/feature/release-notes').then(
      (mod) => mod.ReleaseNotesLauncher,
    ),
  { ssr: false },
);
const Forbidden = dynamic(
  () => import('@dc3/ui/forbidden').then((mod) => mod.Forbidden),
  { ssr: false },
);
const ErrorPage = dynamic(
  () => import('@dc3/ui/error-page').then((mod) => mod.ErrorPage),
  { ssr: false },
);

// https://github.com/mui/material-ui/blob/2ac4439884fd0bbf4bc86143e2aa84261f6ff191/examples/nextjs-with-typescript/pages/_app.tsx
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Add MUI X PRO license key
licenseKey();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const Application = (props: MyAppProps) => {
  const {
    Component,
    router,
    emotionCache = clientSideEmotionCache,
    pageProps,
  } = props;
  useInitFunctionalLogging();
  usePageViewTracking(router);
  const isAuthenticated = Boolean(pageProps.userData?.userData?.userId);

  return (
    <CacheProvider value={emotionCache}>
      <AppProvider
        {...pageProps}
        errorComponent={ErrorPage}
        forbiddenComponent={Forbidden}
        isAuthenticated={isAuthenticated}
      >
        <LinearLoading />

        <Component {...pageProps} />

        <FeedbackLauncher />
        <QuickImpersonateLauncher />
        <ReleaseNotesLauncher />
      </AppProvider>
    </CacheProvider>
  );
};

Application.getInitialProps = getInitialAppProps;

export { Application };
